import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SuccessPage = ({ location }) => (
  <Layout>
    <SEO title="Success" />
    {/* HERO */}
    <div className="hero3">
      <div className="content">
        <h1>
          Success!{" "}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </h1>
        {/* <br /> */}
        {/* <h1>
          Thanks for Getting Started!{" "}
          <span role="img" aria-label="tada">
            🎉
          </span>
        </h1> */}
        {/* <p>Form Successfully Submitted</p> */}
        {/* <p>Thank you!</p> */}
        {/* <p>
          We received your info and we'll be in touch shortly via email. <br />
          We look forward to working with you!{" "}
          <span role="img" aria-label="tada">
            😊
          </span>
        </p> */}
        {/* <p>We've received your info and will be in touch shortly via email.</p> */}

        <p>
          Congrats on getting started
          {location.state ? ", " + location.state.email + "!" : "!"}
        </p>
        {/* <p>We will contact your via email shortly.</p> */}
        {/* <p>
          Congrats on getting started! We will contact your via email shortly.
        </p> */}
        <p>
          We will contact your via email shortly.
          <br />
          We look forward to working with you!{" "}
          <span role="img" aria-label="tada">
            😊
          </span>
        </p>
        {/* <br /> */}
        {/* <p>
          We're excited to start working with you!{" "}
          <span role="img" aria-label="tada">
            😊
          </span>
        </p> */}
        {/* <p>This page does not exist... the sadness.</p> */}
        {/* <p>This is not the page you're looking for.</p> */}

        <p>
          <button onClick={e => window.history.back()}>
            <i className="material-icons">arrow_back</i> Back
          </button>
          <Link to="/">
            <button>
              <i className="material-icons">home</i> Home
            </button>
          </Link>
        </p>
      </div>
    </div>
  </Layout>
)

export default SuccessPage
